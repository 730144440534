import axios from 'axios'
import { useHistory, useNavigate } from 'react-router-dom'



const ROOT = '/vemserver' // root of the server
// const ROOT = ''
const DOMAIN_NAME = window.location.origin + ROOT

export const BASE_URL = DOMAIN_NAME
// const parseJwt = (token) => {
//   try {
//     return JSON.parse(atob(token.split('.')[1]))
//   } catch (e) {
//     return null
//   }
// }

// use localStorage
// export const fetchUserData = async () => {
//
//   const user = JSON.parse(localStorage.getItem("sgdp")) || null
//   if (user) {
//     let auth = false
//     try {
//       await axios.get(BASE_URL + "/auth/auth")
//       auth = true
//     } catch (err) {
//       auth = false
//       console.log("Axios get error")
//     }
//
//     if (auth)
//       return user
//   } else {
//     localStorage.removeItem("sgdp");
//     // history.push("/login")
//     // navigate("/login")
//
//     // EventBus.dispatch("logout");
//     return false
//   }
// }
export const fetchUserData = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/api/vemserver/user-data`, {
      withCredentials: true,
    })
    // console.log(res)
    if (res.data !== null) {
      return res.data
    } else {
      console.log('Login First')
    }
  } catch (error) {
    console.error('Error fetching user data: ')
    throw error
  }
}

export const getTelegram = async () => {
  try {
    const res = await axios.get(BASE_URL + '/api/vemserver/getTelegram')

    if (res.data !== null) {
      return res.data
    } else {
      console.log('Login First')
    }
  } catch (error) {
    console.error('Error : ', error)
    throw error
  }
}
