import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const ErrorBoundary = ({ children }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {

      if (error.status === 401) {
        setError(true)
        navigate('/login')
      }

    }
  }, [error, navigate]);

  return error ? <div>Error: {error.message}</div> : children;
}

